//Bootstrap Toggle Options

$enable-flex:               true ;
$enable-rounded:            false ;
$enable-shadows:            false ;
$enable-gradients:          false ;
$enable-transitions:        false ;
$enable-hover-media-query:  false ;
$enable-grid-classes:       true ;
$enable-print-styles:       true ;

//Link Style Defaults
$link-hover-decoration:         none;

//Override Bootstrap colours here

$brand-primary: #99c031;
$brand-success: #421d58;
$brand-info: #e6077e;
$brand-warning: #ff7a01;
$brand-danger: #FF4F4F;

//Override Bootstrap breakpoints
$grid-breakpoints: (
        xxs: 0,
        xs: 400px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);

//Override Bootstrap container sizes
$container-max-widths: (
        md: 680 * (1px),
        lg: 900 * (1px),
        xl: 1000 * (1px),
        xxl: 1200 * (1px)
);

//Override Bootstrap column count
$grid-columns:               12;

//Override Bootstrap gutter
$grid-gutter-width-base:     30px;

//Override Gutter sizes for different breakpoints
$grid-gutter-widths: (
        xxs: 14px,
        xs: 16px,
        sm: 20px,
        md: $grid-gutter-width-base,
        lg: $grid-gutter-width-base,
        xl: $grid-gutter-width-base,
        xxl: $grid-gutter-width-base
);

//Add extra spacer categories
$spacer:   1rem ;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
        0: (
                x: 0,
                y: 0
        ),
        1: (
                x: ($spacer-x * .25),
                y: ($spacer-y * .25)
        ),
        2: (
                x: ($spacer-x * .5),
                y: ($spacer-y * .5)
        ),
        3: (
                x: $spacer-x,
                y: $spacer-y
        ),
        4: (
                x: ($spacer-x * 1.5),
                y: ($spacer-y * 1.5)
        ),
        5: (
                x: ($spacer-x * 3),
                y: ($spacer-y * 3)
        ),
        6: (
                x: ($spacer-x * 4.5),
                y: ($spacer-y * 4.5)
        )
) !default;