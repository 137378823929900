@import '../site/variables/bootstrap_variables_site';
@import "../../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../../node_modules/ekko-lightbox/dist/ekko-lightbox";
@import '../../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../../node_modules/slick-carousel/slick/slick-theme.scss';
@import '../../../../node_modules/flatpickr/dist/flatpickr';
@import '../../../../node_modules/font-awesome/scss/font-awesome.scss';
@import '../../css/focuspoint';

/* Fix for slick carousel https://github.com/kenwheeler/slick/issues/982 */

*{
  min-height: 0;
  min-width: 0;
}